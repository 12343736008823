import axiosConfig from '../axiosConfig';

export type TAlertDefinition = {
	id: number;
	key_measure_id: number;
	headline: string;
	condition: string;
	value: string;
	color: string;
	period_id: number;
	monitor_window: number;
	actions: string;
	effective_begin_date: number;
	effective_end_date: number;
	triggered: boolean;
	notify_by_email: boolean;
	email_notification_date?: number;
	comparison_type?: string;
	comparison_dropdown?: string;
};

export type TNewAlertDefinition = Omit<TAlertDefinition, 'id'>;

export class AlertDefinition implements TAlertDefinition {
	id: number;
	key_measure_id: number;
	headline: string;
	condition: string;
	value: string;
	color: string;
	period_id: number;
	monitor_window: number;
	actions: string;
	effective_begin_date: number;
	effective_end_date: number;
	triggered: boolean;
	notify_by_email: boolean;
	email_notification_date?: number;
	comparison_type?: string;
	comparison_dropdown?: string;

	constructor(alert: TAlertDefinition) {
		this.id = alert.id;
		this.key_measure_id = alert.key_measure_id;
		this.headline = alert.headline;
		this.condition = alert.condition;
		this.value = alert.value;
		this.color = alert.color;
		this.period_id = alert.period_id;
		this.monitor_window = alert.monitor_window;
		this.actions = alert.actions;
		this.effective_begin_date = alert.effective_begin_date;
		this.effective_end_date = alert.effective_end_date;
		this.triggered = alert.triggered;
		this.notify_by_email = alert.notify_by_email;
		this.email_notification_date = alert.email_notification_date;
		this.comparison_type = alert.comparison_type;
		this.comparison_dropdown = alert.comparison_dropdown;
	}

	public static Default(): TNewAlertDefinition {
		return {
			actions: '',
			color: '',
			condition: '',
			effective_begin_date: 0,
			effective_end_date: 0,
			headline: '',
			key_measure_id: 0,
			monitor_window: 0,
			notify_by_email: false,
			period_id: 0,
			triggered: false,
			value: '',
		};
	}

	public static GetAll(expand: (keyof TAlertDefinition)[] = []): Promise<TAlertDefinition[]> {
		// This requires multiple return endpoints to end in 's' in the API
		return axiosConfig.instance.get('app/alert', { params: { expand: expand } }).then((res) => {
			return ((res.data || []) as TAlertDefinition[]).map((entity) => {
				return entity;
			});
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/alert/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TAlertDefinition): Promise<TAlertDefinition> {
		return axiosConfig.instance.post('app/alert', entity).then((res) => {
			return res.data as TAlertDefinition;
		});
	}

	public static Update(entity: TAlertDefinition): Promise<TAlertDefinition> {
		return axiosConfig.instance.patch('app/alert', entity).then((res) => {
			return res.data as TAlertDefinition;
		});
	}
}

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import '@patternfly/react-core/dist/styles/base.css';
import './index.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Axios } from 'axios';
import Layout from './layout/Layout';
import LoginPage from './routes/root/login/LoginPage';
import Users from './routes/security/Users';
import GettingStarted from './routes/GettingStarted';
import Healthcheck from './routes/Healthcheck';
import PasswordReset from './routes/root/passwordReset/PasswordReset';
import NotFound from './routes/root/NotFound';
import Setup from './routes/Setup';
import Dimensions from './routes/analytics/Dimensions';
import SetupGettingStarted from './routes/SetupGettingStarted';
import DimensionsAttributes from './routes/analytics/DimensionsAttributes';
import KeyMeasures from './routes/analytics/KeyMeasures';
import DimensionSources from './routes/analytics/DimensionSources';
import Periods from './routes/analytics/Periods';
import DateRanges from './routes/analytics/DateRanges';
import ETLSourceDatabases from './routes/analytics/ETLSourceDatabases';
import UnitTypes from './routes/analytics/UnitTypes';
import Groups from './routes/security/Groups';
import Roles from './routes/security/Roles';
import KeyMeasuresSources from './routes/analytics/KeyMeasuresSources';
import ListRecentsFavorites from './routes/analyze/recents-favorites/ListRecentsFavorites';
import ListReportRecentsFavorites from './routes/report/recents-favorites/ListReportRecentsFavorites';
import ListDataframes from './routes/analyze/dataframes/ListDataframes';
import Analyze from './routes/analyze/Analyze';
import BuildDataframe from './routes/dataframes/build-dataframe/BuildDataframe';
import Report from './routes/report/Report';
import Discover from './routes/discover/Discover';
import Category from './routes/discover/Category';
import DiscoverPageContext from './helpers/page-contexts/discover-page-context';
import BuildChart from './routes/charts/BuildChart';
import ListSchedules from './routes/report/schedules/ListSchedules';
import Present from './routes/present/Present';
import PresentPageContext from './helpers/page-contexts/present-page-context';
import Presentation from './routes/present/Presentation';
import PresentationPageContext from './helpers/page-contexts/presentation-page-context';
import PresentationList, { ListType } from './routes/present/PresentationList';
import PresentationDetail from './routes/present/PresentationDetail';
import DashboardDetails from './routes/analyze/dashboard/DashboardDetails';
import { DashboardPageContext } from './helpers/page-contexts/dashboard-page-context';
import Dashboard, { DashboardLayoutType } from './routes/analyze/dashboard/Dashboard';
import ListChildCards from './helpers/helper-components/ListChildCards';
import { CardTypesEnum } from './enums/card-types.enum';
import ChartContainer from './routes/charts/ChartContainer';
import ReloadingPage from './helpers/helper-components/reloading-page';
import DashboardView from './routes/analyze/dashboard/DashboardView';
import { Permission } from './enums/permission.enum';
import ProtectedRoute from './ProtectedRoute';
import Notifications from './routes/app-notifications/Notifications';
import BuildReport from './routes/report/build-report/BuildReport';
import RestrictedAccess from './helpers/helper-components/restricted-access';
import UserDimensionFilters from './routes/security/UserDimensionFilters';
import UserDimensionAttributeFilters from './routes/security/UserDimensionAttributeFilters';
import { DataBuilderTypes } from './enums/data-builder-types';

function axiosLoader() {
	return Axios;
}

const routes = [
	{
		path: '/login',
		element: <LoginPage />,
		loader: axiosLoader,
	},
	{
		path: '/reset-password',
		element: <PasswordReset />,
	},
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/restricted-access',
				element: <RestrictedAccess />,
			},
			{
				path: 'analyze',
				element: <Analyze />,
				children: [
					{
						index: true,
						path: '',
						element: <ListChildCards listType={CardTypesEnum.Dashboard} />,
					},
					{
						path: 'dashboards',
						element: <ListChildCards listType={CardTypesEnum.Dashboard} />,
					},
					{
						path: 'folder/:parentFolderName/:parentFolderId',
						element: <ListChildCards listType={CardTypesEnum.Folder} />,
					},
					{
						path: 'recents',
						element: (
							<ProtectedRoute
								permission={Permission.ViewRecent}
								route={
									<ListRecentsFavorites
										initialTab={0}
										isAnalyze={true}
									/>
								}
								component={ListRecentsFavorites}
								componentProps={{ initialTab: 0, isAnalyze: true }}
							/>
						),
					},
					{
						path: 'favorites',
						element: (
							<ProtectedRoute
								permission={Permission.ViewFavorite}
								route={
									<ListRecentsFavorites
										initialTab={1}
										isAnalyze={true}
									/>
								}
								component={ListRecentsFavorites}
								componentProps={{ initialTab: 1, isAnalyze: true }}
							/>
						),
					},
					{
						path: 'charts',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<ListChildCards listType={CardTypesEnum.Chart} />}
								component={ListChildCards}
								componentProps={{ listType: CardTypesEnum.Chart }}
							/>
						),
					},
					{
						path: 'charts/folder/*',
						element: <ListChildCards listType={CardTypesEnum.Chart} />,
					},
					{
						path: 'folder/*',
						element: <ListChildCards listType={CardTypesEnum.Folder} />,
					},
					{
						path: 'charts/folder/:parentFolderName/:parentFolderId',
						element: <ListChildCards listType={CardTypesEnum.Chart} />,
					},
					{
						path: 'dataframes',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDataframe}
								route={<ListDataframes />}
								component={ListDataframes}
							/>
						),
					},
					{
						path: 'dataframes/folder/:parentFolderName/:parentFolderId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDataframe}
								route={<ListDataframes />}
								component={ListDataframes}
							/>
						),
					},
					{
						path: 'dataframes/folder/*',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDataframe}
								route={<ListDataframes />}
								component={ListDataframes}
							/>
						),
					},
					{
						path: 'tables',
						element: (
							<ProtectedRoute
								permission={Permission.ViewTable}
								route={<ListChildCards listType={CardTypesEnum.Table} />}
								component={ListChildCards}
								componentProps={{ listType: CardTypesEnum.Table }}
							/>
						),
					},
					{
						path: 'table/folder/:parentFolderName/:parentFolderId',
						element: <ListChildCards listType={CardTypesEnum.Table} />,
					},
					{
						path: 'table/folder/*',
						element: <ListChildCards listType={CardTypesEnum.Table} />,
					},
					{
						path: 'loading',
						element: <ReloadingPage />,
					},
				],
			},
			{
				path: 'analyze/dashboards',
				element: (
					<Dashboard
						pageContext={DashboardPageContext}
						type={DashboardLayoutType.Blank}
					/>
				),
				children: [
					{
						path: 'blank',
						element: (
							<ProtectedRoute
								permission={Permission.CreateDashboard}
								route={<DashboardDetails type={DashboardLayoutType.Blank} />}
								component={DashboardDetails}
								componentProps={{ type: DashboardLayoutType.Blank }}
							/>
						),
					},
					{
						path: 'grid',
						element: (
							<ProtectedRoute
								permission={Permission.CreateDashboard}
								route={<DashboardDetails type={DashboardLayoutType.GridTemplate} />}
								component={DashboardDetails}
								componentProps={{ type: DashboardLayoutType.GridTemplate }}
							/>
						),
					},
					{
						path: 'flexible',
						element: (
							<ProtectedRoute
								permission={Permission.CreateDashboard}
								route={
									<DashboardDetails type={DashboardLayoutType.FlexibleTemplate} />
								}
								component={DashboardDetails}
								componentProps={{ type: DashboardLayoutType.FlexibleTemplate }}
							/>
						),
					},
					{
						path: ':dashboardId',
						element: (
							<ProtectedRoute
								permission={Permission.EditDashboard}
								route={<DashboardDetails type={DashboardLayoutType.Blank} />}
								component={DashboardDetails}
								componentProps={{ type: DashboardLayoutType.Blank }}
							/>
						),
					},
				],
			},
			{
				index: true,
				element: <GettingStarted />,
			},
			{
				path: '/getting-started',
				element: <GettingStarted />,
			},
			{
				path: '/healthcheck',
				element: <Healthcheck />,
			},
			{
				path: 'report',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
				children: [
					{
						path: 'recents',
						element: (
							<ProtectedRoute
								permission={Permission.ViewRecent}
								route={<ListReportRecentsFavorites initialTab={0} />}
								component={ListReportRecentsFavorites}
								componentProps={{ initialTab: 0 }}
							/>
						),
					},
					{
						path: 'favorites',
						element: (
							<ProtectedRoute
								permission={Permission.ViewFavorite}
								route={<ListReportRecentsFavorites initialTab={1} />}
								component={ListReportRecentsFavorites}
								componentProps={{ initialTab: 1 }}
							/>
						),
					},
				],
			},
			{
				path: '/setup',
				element: (
					<ProtectedRoute
						permission={Permission.CanViewSettingsPage}
						route={<Setup />}
						component={Setup}
					/>
				),
				children: [
					{
						index: true,
						element: <SetupGettingStarted />,
					},
					{
						path: '/setup',
						element: <SetupGettingStarted />,
					},
					{
						path: '/setup/analytics/dimensions',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDimension}
								route={<Dimensions />}
								component={Dimensions}
							/>
						),
					},
					{
						path: '/setup/analytics/dimension-attributes',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDimensionAttribute}
								route={<DimensionsAttributes />}
								component={DimensionsAttributes}
							/>
						),
					},
					{
						path: '/setup/analytics/key-measures',
						element: (
							<ProtectedRoute
								permission={Permission.ViewKeyMeasure}
								route={<KeyMeasures />}
								component={KeyMeasures}
							/>
						),
					},
					{
						path: '/setup/integration-tools/dimension-sources',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDimensionSource}
								route={<DimensionSources />}
								component={DimensionSources}
							/>
						),
					},
					{
						path: '/setup/settings/periods',
						element: (
							<ProtectedRoute
								permission={Permission.ViewPeriod}
								route={<Periods />}
								component={Periods}
							/>
						),
					},
					{
						path: '/setup/settings/date-ranges',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDateRange}
								route={<DateRanges />}
								component={DateRanges}
							/>
						),
					},
					{
						path: '/setup/integration-tools/etl-source-databases',
						element: (
							<ProtectedRoute
								permission={Permission.ViewEtlSourceDatabase}
								route={<ETLSourceDatabases />}
								component={ETLSourceDatabases}
							/>
						),
					},
					{
						path: '/setup/settings/unit-types',
						element: (
							<ProtectedRoute
								permission={Permission.ViewUnitType}
								route={<UnitTypes />}
								component={UnitTypes}
							/>
						),
					},
					{
						path: '/setup/integration-tools/key-measure-sources',
						element: (
							<ProtectedRoute
								permission={Permission.ViewKeyMeasureSource}
								route={<KeyMeasuresSources />}
								component={KeyMeasuresSources}
							/>
						),
					},
					{
						path: '/setup/security/users',
						element: (
							<ProtectedRoute
								permission={Permission.ViewUser}
								route={<Users />}
								component={Users}
							/>
						),
					},
					{
						path: '/setup/security/roles',
						element: (
							<ProtectedRoute
								permission={Permission.ViewRole}
								route={<Roles />}
								component={Roles}
							/>
						),
					},
					{
						path: '/setup/security/groups',
						element: (
							<ProtectedRoute
								permission={Permission.ViewGroup}
								route={<Groups />}
								component={Groups}
							/>
						),
					},
					{
						path: '/setup/security/user/:userId/dimension/filters',
						element: (
							<ProtectedRoute
								permission={Permission.EditUser}
								route={<UserDimensionFilters />}
								component={UserDimensionFilters}
							/>
						),
					},
					{
						path: '/setup/security/user/:userId/dimension/attribute/filters',
						element: (
							<ProtectedRoute
								permission={Permission.EditUser}
								route={<UserDimensionAttributeFilters />}
								component={UserDimensionAttributeFilters}
							/>
						),
					},
				],
			},
			{
				path: '/discover',
				children: [
					{
						index: true,
						path: '/discover',
						element: (
							<ProtectedRoute
								permission={Permission.CanViewDiscoverPage}
								route={<Discover />}
								component={Discover}
							/>
						),
					},
					{
						path: 'search',
						element: (
							<ProtectedRoute
								permission={Permission.CanViewDiscoverPage}
								route={<Category pageContext={DiscoverPageContext} />}
								component={Category}
								componentProps={{ pageContext: DiscoverPageContext }}
							/>
						),
					},
				],
			},
			{
				path: 'analyze/dataframes',
				element: (
					<ProtectedRoute
						permission={Permission.CanViewAnalyzePage}
						route={<BuildDataframe />}
						component={BuildDataframe}
					/>
				),
				children: [
					{
						path: 'create',
						element: (
							<ProtectedRoute
								permission={Permission.CreateDataframe}
								route={<BuildDataframe />}
								component={BuildDataframe}
							/>
						),
					},
					{
						path: 'edit/:frameId',
						element: (
							<ProtectedRoute
								permission={Permission.EditDataframe}
								route={<BuildDataframe />}
								component={BuildDataframe}
							/>
						),
					},
					{
						path: 'view/:frameId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDataframe}
								route={<BuildDataframe />}
								component={BuildDataframe}
							/>
						),
					},
				],
			},
			{
				path: 'analyze/charts',
				element: (
					<ProtectedRoute
						permission={Permission.CanViewAnalyzePage}
						route={<ChartContainer />}
						component={ChartContainer}
					/>
				),
				children: [
					{
						path: 'build/dataframe/:frameId',
						element: (
							<ProtectedRoute
								permission={Permission.CreateChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'build/chart/:chartId',
						element: (
							<ProtectedRoute
								permission={[Permission.ViewChart]}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'view/chart/:chartId',
						element: (
							<ProtectedRoute
								permission={[Permission.ViewChart]}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'build/dataframe/:frameId/:measureFactId',
						element: (
							<ProtectedRoute
								permission={Permission.CreateChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'build/key-measure-fact/:measureFactId/:seriesId/:seriesAttributeId',
						element: (
							<ProtectedRoute
								permission={Permission.CreateChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
				],
			},
			{
				path: 'analyze/dashboards/:dashboardId/chart',
				element: (
					<ProtectedRoute
						permission={Permission.CanViewAnalyzePage}
						route={<ChartContainer />}
						component={ChartContainer}
					/>
				),
				children: [
					{
						path: 'dataframe/:frameId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'dataframe/:frameId/:measureFactId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: ':chartId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: ':chartId/view',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'key-measure/:measureId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'key-measure-fact/:measureFactId/:seriesId/:seriesAttributeId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
				],
			},
			{
				path: 'present/:presentationId/chart',
				element: (
					<ProtectedRoute
						permission={Permission.CanViewPresentPage}
						route={<ChartContainer />}
						component={ChartContainer}
					/>
				),
				children: [
					{
						path: 'dataframe/:frameId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'dataframe/:frameId/:measureFactId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: ':chartId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'key-measure/:measureId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
					{
						path: 'key-measure-fact/:measureFactId/:seriesAttributeId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewChart}
								route={<BuildChart />}
								component={BuildChart}
							/>
						),
					},
				],
			},
			{
				path: 'analyze/dashboards/:dashboardId/table/create',
				element: (
					<ProtectedRoute
						permission={Permission.CreateReport}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: 'analyze/dashboards/:dashboardId/table/edit/:tableId',
				element: (
					<ProtectedRoute
						permission={Permission.CreateReport}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: 'present/:presentationId/table/create',
				element: (
					<ProtectedRoute
						permission={Permission.CreateReport}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: 'present/:presentationId/table/edit/:tableId',
				element: (
					<ProtectedRoute
						permission={Permission.CreateReport}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: '/report',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
				children: [
					{
						index: true,
						element: (
							<ProtectedRoute
								permission={Permission.ViewReport}
								route={<ListChildCards listType={CardTypesEnum.Report} />}
								component={ListChildCards}
								componentProps={{ listType: CardTypesEnum.Report }}
							/>
						),
					},
					{
						path: 'folder/:parentFolderName/:parentFolderId',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDataframe}
								route={<ListChildCards listType={CardTypesEnum.Report} />}
								component={ListChildCards}
								componentProps={{ listType: CardTypesEnum.Report }}
							/>
						),
					},
					{
						path: 'folder/*',
						element: (
							<ProtectedRoute
								permission={Permission.ViewDataframe}
								route={<ListChildCards listType={CardTypesEnum.Report} />}
								component={ListChildCards}
								componentProps={{ listType: CardTypesEnum.Report }}
							/>
						),
					},
					{
						path: 'favorites',
						element: (
							<ProtectedRoute
								permission={Permission.ViewReport}
								route={<ListReportRecentsFavorites initialTab={1} />}
								component={ListReportRecentsFavorites}
								componentProps={{ initialTab: 1 }}
							/>
						),
					},
					{
						path: 'recents',
						element: (
							<ProtectedRoute
								permission={Permission.ViewReport}
								route={<ListReportRecentsFavorites initialTab={0} />}
								component={ListReportRecentsFavorites}
								componentProps={{ initialTab: 0 }}
							/>
						),
					},
					{
						path: 'schedules',
						element: (
							<ProtectedRoute
								permission={Permission.ViewReportSchedule}
								route={<ListSchedules />}
								component={ListSchedules}
							/>
						),
					},
					{
						path: 'queue',
					},
				],
			},
			{
				path: 'report/global',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
			},
			{
				path: 'report/redemptions',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
			},
			{
				path: 'report/people',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
			},
			{
				path: 'report/rewards',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
			},
			{
				path: 'report/retention',
				element: (
					<ProtectedRoute
						permission={Permission.ViewReport}
						route={<Report />}
						component={Report}
					/>
				),
			},
			{
				path: 'report/create',
				element: (
					<ProtectedRoute
						permission={Permission.CreateReport}
						route={<BuildReport type={DataBuilderTypes.report} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.report }}
					/>
				),
			},
			{
				path: 'report/edit/:reportId',
				element: (
					<ProtectedRoute
						permission={[Permission.EditReport]}
						route={<BuildReport type={DataBuilderTypes.report} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.report }}
					/>
				),
			},
			{
				path: 'report/view/:reportId',
				element: (
					<ProtectedRoute
						permission={[Permission.ViewReport]}
						route={<BuildReport type={DataBuilderTypes.report} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.report }}
					/>
				),
			},
			{
				path: 'table/create',
				element: (
					<ProtectedRoute
						permission={Permission.CreateTable}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: 'table/edit/:tableId',
				element: (
					<ProtectedRoute
						permission={[Permission.EditTable]}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: 'table/view/:tableId',
				element: (
					<ProtectedRoute
						permission={[Permission.ViewTable]}
						route={<BuildReport type={DataBuilderTypes.table} />}
						component={BuildReport}
						componentProps={{ type: DataBuilderTypes.table }}
					/>
				),
			},
			{
				path: '/present',
				element: <Present pageContext={PresentPageContext} />,
				children: [
					{
						index: true,
						element: <PresentationList pageContext={PresentPageContext} />,
					},
					{
						path: ':presentationId',
						element: <Presentation />,
					},
					{
						path: 'recents',
						element: (
							<PresentationList
								pageContext={PresentPageContext}
								listType={ListType.Recent}
							/>
						),
					},

					{
						path: 'favorites',
						element: (
							<PresentationList
								pageContext={PresentPageContext}
								listType={ListType.Favorite}
							/>
						),
					},
					{
						path: 'folder/:folderId',
						children: [
							{
								index: true,
								element: (
									<PresentationList
										pageContext={PresentPageContext}
										listType={ListType.Recent}
									/>
								),
							},
							{
								path: 'search',
								element: <Present pageContext={PresentPageContext} />,
							},
							{
								path: 'new',
								element: <Presentation />,
							},
							{
								path: ':presentationId',
								element: <Presentation />,
							},
						],
					},
				],
			},
			{
				path: '/present',
				element: <Presentation />,
				children: [
					{
						index: true,
						element: <PresentationDetail pageContext={PresentationPageContext} />,
					},
					{
						path: 'edit/:presentationId',
						element: <PresentationDetail pageContext={PresentationPageContext} />,
					},
					{
						path: 'blank',
						element: <PresentationDetail pageContext={PresentationPageContext} />,
					},
					{
						path: 'grid',
						element: <PresentationDetail pageContext={PresentationPageContext} />,
					},
					{
						path: 'flexible',
						element: <PresentationDetail pageContext={PresentationPageContext} />,
					},
					{
						path: 'copy',
						element: <PresentationDetail pageContext={PresentationPageContext} />,
					},
					{
						path: ':parentFolderName/:parentFolderId',
						element: <ListChildCards listType={CardTypesEnum.Presentation} />,
					},
				],
			},
			{
				path: '/analyze/dashboards/view/:dashboardId',
				element: (
					<ProtectedRoute
						permission={Permission.ViewDashboard}
						route={<DashboardView />}
						component={DashboardView}
					/>
				),
			},
			{
				path: 'notifications',
				element: (
					<ProtectedRoute
						permission={Permission.ViewZoneNotification}
						route={<Notifications />}
						component={Notifications}
					/>
				),
			},
		],
	},
	{
		path: '*',
		element: <NotFound />,
	},
];

const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Button, Form, FormGroup, TextInput } from '@patternfly/react-core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { TFolder } from '../../../api/foundational-elements/Folder';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeViewSelect from '../../../components/form/Select/TreeViewSelect';
import EntityMiscButtons from '../../../helpers/helper-components/EntityMiscButtons';
import { MultipartResponse } from '../../../helpers/multipart-response.helper';
import { DataframeDataRetrievalResponse } from '../../../api/types';
import DatePeriodSelector from '../../../components/date-period-selector/DatePeriodSelector';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import FilterModal from '../../../components/modals/databuilder/FilterModal';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

type Props = {
	name?: string;
	folders: TFolder[];
	folder?: TFolder;
	onRunReport: () => void;
	parameters: DraggableMenuItemData[];
	setParameters: Dispatch<SetStateAction<DraggableMenuItemData[]>>;
	setSelectedDate: Dispatch<SetStateAction<TNewDateRange | undefined>>;
	previewData?: MultipartResponse<DataframeDataRetrievalResponse>;
};

const ViewReport = (props: Props) => {
	const {
		name,
		folders,
		folder,
		onRunReport,
		parameters,
		setParameters,
		setSelectedDate,
		previewData,
	} = props;

	const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
	const [activeFilter, setActiveFilter] = useState<DraggableMenuItemData>();

	const handleFilterModalOpen = (param: DraggableMenuItemData) => {
		setActiveFilter(param);
		setIsFilterModalOpen(true);
	};

	const handleFilterModalSave = (newParam: DraggableMenuItemData) => {
		setParameters(
			parameters.map((param) => {
				if (
					param.entityType === newParam.entityType &&
					param.data?.id === newParam.data?.id
				) {
					if (param.data) {
						param.data.showSetFilterIcon = true;
					}
					param = newParam;
				}
				return param;
			})
		);
		setIsFilterModalOpen(false);
	};

	const handleFilterModalClose = () => {
		setIsFilterModalOpen(false);
	};

	const updateSelectedDate = (startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
		if (setSelectedDate) {
			setSelectedDate({
				begin_date: startDateRange.begin_date,
				end_date: endDateRange.end_date,
				period: startDateRange.period,
				sequence: startDateRange.sequence,
			});
		}
	};

	return (
		<div className={'view-report-container'}>
			<div className={'details-container'}>
				<div className={'details'}>
					<div className={'form-container'}>
						<Form>
							<FormGroup
								label={'Report Name'}
								type="text"
								fieldId="name"
							>
								<TextInput
									type="text"
									value={name}
									isDisabled={true}
								/>
							</FormGroup>
							<FormGroup
								label={<FontAwesomeIcon icon={faFolder} />}
								type="text"
								fieldId="folder"
								className="folder-container"
							>
								<TreeViewSelect
									data={
										folders
											? folders.map((_: TFolder) =>
													BuildTreeViewItem(_, _.items)
											  )
											: []
									}
									placeHolderText={folder ? folder.name : 'No Selected Folder'}
									isDisabled={true}
								/>
							</FormGroup>
						</Form>
					</div>
					<div className={'actions-container'}>
						<Button
							variant="primary"
							onClick={onRunReport}
						>
							Run Report
						</Button>
					</div>
				</div>
			</div>
			<div className={'misc-actions'}>
				<EntityMiscButtons
					entityType={'Report'}
					entityName={name ?? ''}
					canShare={false}
					exportData={previewData}
					limitReached={previewData?.json?.result_size?.limitReached}
					isView={true}
				/>
			</div>
			<div className={'filters'}>
				<Form isHorizontal>
					<FormGroup
						label="Filters"
						isInline
					>
						<div className="date-selector-container">
							<DatePeriodSelector applyDateRange={updateSelectedDate} />
						</div>

						{parameters.map((param) => {
							return (
								<>
									<div className={`custom-button-chip`}>
										<div
											className="label"
											onClick={() => {
												handleFilterModalOpen(param);
											}}
										>
											{param.data?.title}
										</div>
										{param.data?.showSetFilterIcon && (
											<>
												<FontAwesomeIcon icon={faFilter} />
												<>&nbsp;</>
											</>
										)}
									</div>
								</>
							);
						})}
					</FormGroup>
				</Form>
			</div>
			{isFilterModalOpen && (
				<FilterModal
					filter={activeFilter ?? null}
					handleClose={handleFilterModalClose}
					handleSave={handleFilterModalSave}
					readonly={activeFilter?.data?.disabled}
				/>
			)}
		</div>
	);
};

export default ViewReport;

import axiosConfig from '../axiosConfig';
import { TDashboard } from './Dashboards';

export type TDashboardWidget = {
	id?: number;
	col: number;
	row: number;
	sizex: number;
	sizey: number;
	dashboard: TDashboard | number;
	name: string;
	widget_type: 'text' | 'pivot_table' | 'notes' | 'chart' | 'image' | 'base';
	content?: string;
	chart?: number;
	report?: number;
	source?: string;
	isNew?: boolean;
	widgetChanged?: boolean;
	isEdit?: boolean;
};

export class DashboardWidget implements TDashboardWidget {
	id?: number;
	col: number;
	row: number;
	sizex: number;
	sizey: number;
	dashboard: TDashboard | number;
	name: string;
	widget_type: 'text' | 'pivot_table' | 'notes' | 'chart' | 'image' | 'base';
	content?: string;
	chart?: number;
	report?: number;
	source?: string;
	isNew?: boolean;
	widgetChanged?: boolean;
	isEdit?: boolean;

	constructor(dashboardWidget: TDashboardWidget) {
		this.id = dashboardWidget.id;
		this.col = dashboardWidget.col;
		this.row = dashboardWidget.row;
		this.sizex = dashboardWidget.sizex;
		this.sizey = dashboardWidget.sizey;
		this.dashboard = dashboardWidget.dashboard;
		this.name = dashboardWidget.name;
		this.widget_type = dashboardWidget.widget_type;
		this.content = dashboardWidget.content;
		this.chart = dashboardWidget.chart;
		this.source = dashboardWidget.source;
		this.isNew = dashboardWidget.isNew;
		this.widgetChanged = dashboardWidget.widgetChanged;
		this.report = dashboardWidget.report;
		this.isEdit = dashboardWidget.isEdit;
	}

	public static Default(): TDashboardWidget {
		return {
			name: '',
			col: 0,
			row: 0,
			sizex: 0,
			sizey: 0,
			widget_type: 'base',
			dashboard: 0,
		};
	}

	public static New(dashboardWidget: TDashboardWidget): Promise<TDashboardWidget> {
		return axiosConfig.instance.post('app/dashboard-widget', dashboardWidget).then((res) => {
			return new DashboardWidget(res.data as TDashboardWidget);
		});
	}

	public static Edit(dashboardWidget: TDashboardWidget): Promise<TDashboardWidget> {
		return axiosConfig.instance.patch('app/dashboard-widget', dashboardWidget).then((res) => {
			return new DashboardWidget(res.data as TDashboardWidget);
		});
	}

	public static Get(dashboardWidgetId: number): Promise<TDashboardWidget> {
		return axiosConfig.instance.get(`app/dashboard-widget/${dashboardWidgetId}`).then((res) => {
			return new DashboardWidget(res.data as TDashboardWidget);
		});
	}

	public static GetAll(
		filter: Record<string, string>,
		expand: (keyof TDashboardWidget)[] = []
	): Promise<TDashboardWidget[]> {
		let url = 'app/dashboard-widgets';
		if (filter) {
			let joinedFilterString = '';
			for (const property in filter) {
				if (joinedFilterString !== '') {
					joinedFilterString = `${joinedFilterString}&`;
				}
				joinedFilterString = `${joinedFilterString}filter[${property}]=${filter[property]}`;
			}
			url = `${url}?${joinedFilterString}`;
		}
		return axiosConfig.instance
			.get<TDashboardWidget[]>(url, { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}

	public static Delete(dashboardWidgetId: number): Promise<boolean> {
		return axiosConfig.instance
			.delete(`app/dashboard-widget/${dashboardWidgetId}`)
			.then((res) => {
				return res.data as boolean;
			});
	}
}

import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	CardHeader,
	Flex,
	FlexItem,
	Grid,
	GridItem,
	Text,
} from '@patternfly/react-core';
import { Dashboard } from '../../../api/dashbboards/Dashboards';
import { DashboardWidget } from '../../../api/dashbboards/DashboardWidgets';
import { useMount } from 'react-use';
import DropGrid from '../../../components/dnd/DropGrid';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { OutletContext } from '../../../layout/Layout';
import PageTitleSubheader from '../../../layout/subheader/PageTitleSubheader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/util/Loader';
import Authorized from '../../../components/security/Authorized';
import { Permission } from '../../../enums/permission.enum';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import './DashboardView.scss';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import { addNewRecentDashboard } from '../../../helpers/helper-components/recents-factory-helper';
import DashboardFilters from './DashboardFilters';
import { DashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import EntityMiscButtons from '../../../helpers/helper-components/EntityMiscButtons';
import { SharedEntityUser, TSharedEntityUser } from '../../../api/shared-entity/SharedEntityUser';
import { useUser } from '../../../components/user/ApplicationProvider';
import { TSharedEntity } from '../../../api/shared-entity/SharedEntity';
import { Favorites, TFavorites } from '../../../api/favorites/Favorites';
import { FavoriteTypes } from '../../../enums/favorite-types';

const DashboardView = () => {
	const { dashboardId } = useParams();
	const navigate = useNavigate();
	const { addToast } = useToast();
	const [loading, setLoading] = useState<boolean>(false);
	const [dashboardModel, setDashboardModel] = useState<Dashboard>({
		...Dashboard.Default(),
	});
	const [selectedDate, setSelectedDate] = useState<TNewDateRange>();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [dashboardFilters, setDasboardFilters] = useState<DashboardFilter[]>();
	const currentUser = useUser();
	const [favorite, setFavorite] = useState<TFavorites>();
	const [userSharedPermisson, setUserSharedPermission] = useState<TSharedEntityUser>(
		SharedEntityUser.Default()
	);

	const updateSelectedDate = useCallback(
		(startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
			if (setSelectedDate) {
				setSelectedDate({
					begin_date: startDateRange.begin_date,
					end_date: endDateRange.end_date,
					period: startDateRange.period,
					sequence: startDateRange.sequence,
				});
			}
		},
		[selectedDate, setSelectedDate]
	);

	useMount(() => {
		if (dashboardId) {
			setLoading(true);
			Dashboard.Get(parseInt(dashboardId), ['sharedDashboards'])
				.then((dashboardResponse) => {
					if (dashboardResponse) {
						setDashboardModel({ ...dashboardResponse });
						DashboardWidget.GetAll({ dashboard: dashboardId })
							.then((widgetsResponse) => {
								setWidgets(widgetsResponse);
								setLoading(false);
							})
							.catch(() => {
								addToast('Get all widgets failed.', AlertVariant.danger);
							});
					}
				})
				.catch(() => {
					addToast('Get dashboard failed.', AlertVariant.danger);
				});

			getFavoriteData();

			//log entry into recents for loaded dashboard
			addNewRecentDashboard(dashboardId);
		}
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle={''}
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
				/>
			),
			hideLeftSideBar: true,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const getDashboardSharedPermissions = () => {
		let userSharedRecord: { canEdit: boolean; canShare: boolean } = {
			canEdit: false,
			canShare: false,
		};
		if (!dashboardModel || currentUser.id === dashboardModel?.owner) {
			userSharedRecord = {
				canEdit: true,
				canShare: true,
			};
		} else {
			dashboardModel?.sharedDashboards?.map((sd) => {
				const thisUser = (sd as TSharedEntity).shared_entity_users?.find(
					(u) => u.user === currentUser.id
				);
				const thisGroup = (sd as TSharedEntity).shared_entity_groups?.find(
					(u) => currentUser.groups.findIndex((ug) => ug === u.group) > -1
				);
				if (thisUser) {
					userSharedRecord = { canEdit: thisUser.can_edit, canShare: thisUser.can_share };
				} else if (thisGroup) {
					userSharedRecord = {
						canEdit: thisGroup.can_edit,
						canShare: thisGroup.can_share,
					};
				}
			});
		}

		return userSharedRecord;
	};

	const getFavoriteData = () => {
		Favorites.Get(+(dashboardId ?? 0), currentUser.id)
			.then((response: TFavorites) => {
				if (response) {
					setFavorite(response);
				} else {
					setFavorite({
						user: currentUser.id,
						object_id: +(dashboardId ?? 0),
						type: FavoriteTypes.dashboard,
						name: dashboardModel?.name,
					});
				}
			})
			.catch(() => {
				addToast(
					'There was an issue setting the dashboard as a favorite',
					AlertVariant.danger
				);
			});
	};

	const [widgets, setWidgets] = useState<DashboardWidget[]>([]);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Authorized
				by={Permission.ViewDashboard}
				shouldRedirect
			>
				<Card className="dashboard-view-container">
					<CardHeader>
						<Flex flex={{ default: 'flex_1' }}>
							<FlexItem>
								<Text component="h1">{dashboardModel.name}</Text>
							</FlexItem>
							<FlexItem align={{ default: 'alignRight' }}>
								{getDashboardSharedPermissions().canEdit && (
									<Authorized by={Permission.EditDashboard}>
										<Button
											variant="plain"
											aria-label="Edit Dashboard"
											className="edit-dashboard"
										>
											<FontAwesomeIcon
												icon={faPencil}
												className="edit-icon"
												size="xl"
												title="Edit"
												onClick={(e) => {
													e.stopPropagation();
													navigate(
														`/analyze/dashboards/${
															dashboardModel.id ?? 0
														}`
													);
												}}
											/>
										</Button>
									</Authorized>
								)}
								<EntityMiscButtons
									entityType={'Dashboard'}
									entityName={dashboardModel.name}
									canShare={getDashboardSharedPermissions().canShare}
									favorite={favorite}
									setFavorite={getFavoriteData}
									isView={true}
								/>
							</FlexItem>
						</Flex>

						<DashboardFilters
							dashboardId={dashboardModel.id ?? 0}
							setFilters={setDasboardFilters}
							setSelectedDate={updateSelectedDate}
							isView
						/>
					</CardHeader>
					<CardBody>
						<Grid hasGutter>
							<GridItem span={12}>
								{!dashboardFilters ? (
									<Loader />
								) : (
									<DropGrid
										displayOnly
										widgets={widgets}
										selectedDate={selectedDate}
										gridBorder={dashboardModel.hasBorders ?? false}
										filters={dashboardFilters}
									/>
								)}
							</GridItem>
						</Grid>
					</CardBody>
				</Card>
			</Authorized>
		</>
	);
};

export default DashboardView;

import { AlertVariant, Modal, ModalVariant, Spinner } from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { AlertDefinition, TAlertDefinition, TNewAlertDefinition } from '../../api/alerts/Alert';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { useMount } from 'react-use';
import { KeyMeasure, TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { PeriodTypesEnum } from '../../enums/period-types-enum';
import { AlertConditionsEnum } from '../../enums/alet-conditions.enum';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	droppedFacts: DraggableMenuItemData[];
};

function AlertDefinitionModal(props: Props) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { addToast } = useToast();
	const [activeAlert, setActiveAlert] = useState<TAlertDefinition>(
		AlertDefinition.Default() as TAlertDefinition
	);
	const [keymeasures, setKeymeasures] = useState<TKeyMeasure[]>([]);
	const [initialFormProperties, setInitialFormProperties] = useState<
		Field<TNewAlertDefinition>[]
	>([]);
	const [currentFormProperties, _setCurrentFormProperties] = useState<
		Field<TNewAlertDefinition>[]
	>([]);
	const [excludedKeymeasureFactId, setExcludedKeymeasureFactId] = useState<number>();

	useMount(() => {
		void KeyMeasure.GetAll(['keyMeasureFacts']).then((response) => {
			const filteredKeymeasures = response
				.map((keymeasure) => {
					// Filter the KeyMeasureFacts to only include those that match droppedFactsKeyValue
					const filteredFacts = keymeasure.keyMeasureFacts.filter((fact) =>
						droppedFactsKeyValue.some((droppedFact) => droppedFact.id === fact.id)
					);

					// Return the keymeasure only if there are matching facts
					return filteredFacts.length > 0
						? { ...keymeasure, keyMeasureFacts: filteredFacts }
						: null;
				})
				.filter((keymeasure) => keymeasure !== null) as TKeyMeasure[]; // Remove any keymeasures without matching facts

			setKeymeasures(filteredKeymeasures);
		});
	});

	useEffect(() => {
		if (props.isOpen) {
			const element = document.getElementsByClassName('pf-c-form__group')[9] as HTMLElement;
			if (element) {
				element.style.display = 'none';
			}

			const elements = document.querySelectorAll('.pf-c-form__label-text');

			// Find the element with the inner text 'Comparison Type'
			const targetElement = Array.from(elements).find(
				(element) => (element as HTMLElement).outerText === 'Comparison Type'
			);

			if (targetElement) {
				targetElement.outerHTML = `<div class="pf-c-form__group-label">
						<label class="pf-c-form__label" for="key-measure-alerts-comparison_type">
							<span class="pf-c-form__label-text">Comparison Type</span>
							<span class="pf-c-form__label-required" aria-hidden="true"> *</span>
						</label> 
					</div>`;
			}
		} else {
			setExcludedKeymeasureFactId(undefined);
		}
	}, [props.isOpen]);

	const droppedFactsKeyValue = props.droppedFacts.map((x) => ({
		id: x.data?.id,
		value: x.data?.title,
	}));

	const returnPeriodIdForPeriod = (period: string) => {
		switch (period) {
			case 'Year':
				return 1;
			case 'Quarter':
				return 2;
			case 'Month':
				return 3;
			case 'Week':
				return 4;
			case 'Day':
				return 5;
		}
	};

	const returnKmfIdForKmf = (kmfName: string) => {
		const idToReturn = droppedFactsKeyValue.find((x) => x.value == kmfName)?.id;

		if (idToReturn) setExcludedKeymeasureFactId(idToReturn);

		return idToReturn;
	};

	const formProperties: Field<TNewAlertDefinition>[] = [
		{
			title: 'Key Measure',
			columnName: 'key_measure_id',
			uiSchema: {
				type: UIType.KEYMEASURE_FACT_SELECT,
				kmOptions: keymeasures,
				onSelect: (value: ISelectOption) => returnKmfIdForKmf(value.key.toString()),
			},
			required: true,
		},
		{
			title: 'Operator',
			columnName: 'condition',
			uiSchema: {
				type: UIType.SELECT,
				options: Object.values(AlertConditionsEnum).map(
					(condition) => ({ key: condition, value: condition } as ISelectOption)
				),
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Comparison Type',
			columnName: 'comparison_type',
			uiSchema: {
				type: UIType.RADIO,
				options: [
					{ key: 'value', value: 'Value', selectedByDefault: true },
					{ key: 'comparison', value: 'Comparison' },
				] as ISelectOption[],
				onSelect: (selectedOption: ISelectOption) => updateComparisonType(selectedOption),
			},
		},
		{
			title: 'Value',
			columnName: 'value',
			uiSchema: {
				type: UIType.TEXT,
			},
		},
		{
			title: 'Key Measure Comparison',
			columnName: 'comparison_dropdown',
			uiSchema: {
				type: UIType.KEYMEASURE_FACT_SELECT,
				kmOptions: excludedKeymeasureFactId
					? keymeasures.filter((x) =>
							x.keyMeasureFacts.some((y) => y.id != excludedKeymeasureFactId)
					  )
					: keymeasures,
				onSelect: (value: ISelectOption) => returnKmfIdForKmf(value.key.toString()),
			},
		},
		{
			title: 'Triggered Color',
			columnName: 'color',
			uiSchema: {
				type: UIType.COLOR_PICKER,
			},
		},
		{
			title: 'Period',
			columnName: 'period_id',
			uiSchema: {
				type: UIType.SELECT,
				options: Object.values(PeriodTypesEnum).map(
					(period) =>
						({ key: returnPeriodIdForPeriod(period), value: period } as ISelectOption)
				),
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Monitoring Window (Days)',
			columnName: 'monitor_window',
			uiSchema: {
				type: UIType.NUMBER,
			},
		},
		{
			title: 'Action(s) to be Taken',
			columnName: 'actions',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
		},
		{
			title: 'Notify Via Email',
			columnName: 'notify_by_email',
			uiSchema: {
				type: UIType.CHECKBOX,
			},
		},
		{
			title: 'Last Notified Date',
			columnName: 'email_notification_date',
			uiSchema: {
				type: UIType.DATE,
			},
			disabled: true,
		},
	];

	useEffect(() => {
		setInitialFormProperties(formProperties);
	}, []);

	useEffect(() => {
		setInitialFormProperties(formProperties);
	}, [keymeasures, excludedKeymeasureFactId]);

	//eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	function getPropertyValue(obj: Record<string, any>, propName: string) {
		//eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return obj[propName];
	}

	const updateComparisonType = (value: ISelectOption) => {
		//eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
		const actualParentSelectedOption = getPropertyValue(value, 'key');

		//eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
		const actualSelectedOption = getPropertyValue(actualParentSelectedOption, 'key');

		//as long as the current form properties list does not change this will work,
		//if the formProperties list gets updated or new items gets added this code needs to get updated as well
		//to reflect the correct indexes for comparison dropdown & comparison value fields
		if (actualSelectedOption == 'comparison') {
			const element = document.getElementsByClassName('pf-c-form__group')[9] as HTMLElement;
			if (element) {
				element.style.display = 'initial';
			}

			const element2 = document.getElementsByClassName('pf-c-form__group')[8] as HTMLElement;
			if (element2) {
				element2.style.display = 'none';
			}
		} else {
			const element = document.getElementsByClassName('pf-c-form__group')[9] as HTMLElement;
			if (element) {
				element.style.display = 'none';
			}

			const element2 = document.getElementsByClassName('pf-c-form__group')[8] as HTMLElement;
			if (element2) {
				element2.style.display = 'initial';
			}
		}

		return value.key;
	};

	const Save = (alert: TAlertDefinition) => {
		setIsLoading(true);

		if (alert.id) {
			AlertDefinition.Update(alert)
				.then((_updatedResponse) => {
					props.onClose();
				})
				.catch(() => {
					addToast(
						'An error occurred while trying to save the User. Please try again later.',
						AlertVariant.danger
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			AlertDefinition.New(alert)
				.then((_reponse) => {
					props.onClose();
				})
				.catch(() => {
					addToast(
						'An error occurred while trying to save the User. Please try again later.',
						AlertVariant.danger
					);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	return (
		<div>
			<Modal
				title="Key Measure Alerts"
				variant={ModalVariant.small}
				isOpen={props.isOpen}
				onClose={props.onClose}
			>
				<div>
					{isLoading && (
						<div className="spinner-container">
							<Spinner size={'lg'} />
						</div>
					)}
					{!isLoading && (
						<>
							<SchnurForm<TAlertDefinition>
								title={'Key Measure Alerts'}
								fields={
									currentFormProperties && currentFormProperties.length > 0
										? currentFormProperties
										: initialFormProperties
								}
								initialSubject={activeAlert}
								isLoading={isLoading}
								onSubmit={(alert) => {
									Save(alert);
								}}
							/>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
}

export default AlertDefinitionModal;
